<template>
  <b-overlay :show="isSimUpdating" rounded="sm">
    <panel class="sim-management-panel" id="trackerEditPanel" :title="'Sim Management'">
      <template slot="afterTitle">
        <button type="button" @click="updateSimStatus" class="btn btn-orange ml-2 py-1 px-2">
          <i :class="{'icon-spinner': isSimUpdating}" class="fas fa-sync-alt mr-2"></i> SIM Status
        </button>
        <button :disabled="simManagement.active" type="button" @click="activateSim" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fa fa-clipboard-check mr-2"></i> Activate SIM
        </button>
        <button :disabled="!simManagement.active" type="button" @click="deactivateSim" class="btn btn-red ml-2 py-1 px-2">
          <i class="fa fa-clipboard-check mr-2"></i> Deactivate SIM
        </button>
      </template>
      <div class="row mb-3">
        <div class="offset-1 col-1">
          <label>MSISDN</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="simManagement.msisdn" readonly>
        </div>
        <div class="offset-1 col-1">
          <label>Active</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="simManagement.active" readonly>
        </div>
      </div>
      <div class="row mb-3">
        <div class="offset-1 col-1">
          <label>Status</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="simManagement.status" readonly>
        </div>
        <div class="offset-1 col-1">
          <label>First Time Attach</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="parseDatetime(simManagement.firstTimeAttach)" readonly>
        </div>
      </div>
      <div class="row mb-3">
        <div class="offset-1 col-1">
          <label>Data Actual (MB)</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="convertCreditToMb(simManagement.creditActual)" readonly>
        </div>
        <div class="offset-1 col-1">
          <label>Termination Date</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="parseDatetime(simManagement.terminationDate)" readonly>
        </div>
      </div>
      <div class="row mb-3">
        <div class="offset-1 col-1">
          <label>Data Limit (MB)</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="convertCreditToMb(simManagement.creditLimit)" readonly>
        </div>
        <div class="offset-1 col-1">
          <label>Last Updated At</label>
        </div>
        <div class="col-3">
          <input class="form-control" :value="parseDatetime(simManagement.updatedAt)" readonly>
        </div>
      </div>
    </panel>
  </b-overlay>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { simStatus, getSimByIccid, simActivate, simDeactivate } from '@/services/sim'
import { mapState, mapGetters } from 'vuex'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

export default {
  data() {
    return {
      simManagement: {},
      isSimUpdating: false,
    }
  },
  watch: {
    tracker: {
      async handler() {
        await this.refresh()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('tracker', ['tracker']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
  },
  methods: {
    async refresh() {
      // Fetch sim management data
      const simManagementData = (await getSimByIccid(this.tracker.ccid)).data || []
      this.simManagement = simManagementData[0] || {}
    },

    parseDatetime(datetime) {
      if (!datetime) return ''
      return moment(datetime).format(this.USER_DATETIME_FORMAT)
    },

    convertCreditToMb(bytes) {
      return (bytes / (1024*1024)).toFixed(6)
    },

    async updateSimStatus() {
      this.isSimUpdating = true
      try {
        const response = await simStatus(this.tracker.ccid)
        if (response?.data) {
          if (response.data.error) throw new Error(response.data.error)
          this.simManagement = response.data
          this.$toast('Sucessfully completed', {
            className: ['bg-success']
          })
        }
      } catch(e) {
        this.$toast(e, { className: ['et-alert'] })
      }
      setTimeout(() => {
        this.isSimUpdating = false
      }, 1000)
    },
    
    async activateSim() {
      const { isConfirmed } = await Swal.fire({
        html: '<span> Are you sure you want to Activate SIM?</span>',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        this.isSimUpdating = true
        try {
          const response = await simActivate({ iccid: this.tracker.ccid })
          if (response?.data) {
            if (response.data.error) throw new Error(response.data.error)
            this.simManagement = response.data
            this.$toast('Sucessfully completed', {
              className: ['bg-success']
            })
          }
        } catch(e) {
          this.$toast(e, { className: ['et-alert'] })
        }
        setTimeout(() => {
          this.isSimUpdating = false
        }, 1000)
      }
    },

    async deactivateSim() {
      const { isConfirmed } = await Swal.fire({
        html: '<span> Are you sure you want to Deactivate SIM?</span>',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        this.isSimUpdating = true
        try {
          const response = await simDeactivate(this.tracker.ccid)
          if (response?.data) {
            if (response.data.error) throw new Error(response.data.error)
            this.simManagement = response.data
            this.$toast('Sucessfully completed', {
              className: ['bg-success']
            })
          }
        } catch(e) {
          this.$toast(e, { className: ['et-alert'] })
        }
        setTimeout(() => {
          this.isSimUpdating = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
.sim-management-panel {
  .icon-spinner {
    animation: spin-animation 0.75s infinite;
    display: inline-block;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}
</style>
